<template>
<div class="main">
  <VxeOrgTree :service="service" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      service: "/sys/sysOrganization/treeQueryAuthCommon/room",
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "feeMonth",
            title: "费用期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入费用期"
              }
            }
          },
          {
            field: "feeConfigName",
            title: "费用项名",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入费用项名"
              }
            }
          },
          {
            field: "ownerName",
            title: "业主名",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入业主名"
              }
            }
          },
          {
            field: "roomName",
            title: "房屋编号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号"
              }
            }
          },

          {
            field: "receivedAmount",
            title: "实收金额",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
          {
            field: "usageAmount",
            title: "建筑面积",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入建筑面积"
              }
            }
          }
        ],
        exportConfig: {},
        importConfig: {},
        editConfig: {
          enabled: false
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {},
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      if (node.type == '1004') {
        this.searchOptions.data['communityId'] = node.communityId;
        this.searchOptions.data['roomId'] = node.id;
        this.$refs.xGrid.getLists();
      } else {
        this.$message.error("请选择房屋");
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
